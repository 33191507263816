import React, { useState } from 'react';
import './Form.css'; // Importe o arquivo CSS
import DemoBook from '../newsletter/DemoBook';
function Formulario() {
    return (
        <div className="formulario">
            <h2>Preencha o formulário para obter de forma gratuita o Ebook: Código Deontológico de Boas Práticas da Investigação Defensiva</h2>
            <DemoBook />
        </div>
    );
}

export default Formulario;
