import React from "react";

function Descricao() {

  return (
    <div className="descricao">
      <h2>Descrição</h2>
      <p>
        A Comissão de Investigação Defensiva e Novas Tecnologias da Associação Brasileira dos Advogados Criminalistas (Abracrim), através de seu presidente, Gabriel Bulhões, sua vice-presidente, Michelle Aguiar, e seu secretário-geral, Rodrigo Camargo, organizou o Código Deontológico de Boas Práticas da Investigação Defensiva.
      </p>
      <p>
        A obra conta com 24 profissionais de diversas áreas do Direito (Peritos, Professores, Advogados e Defensores Públicos). A publicação se orienta pelo reconhecimento da necessidade da estruturação de normas de conduta ético-profissionais para o exercício da atividade, propondo parâmetros de atuação com a finalidade de auxiliar as ações próprias da investigação defensiva .
      </p>

    </div>
  );
}

export default Descricao;
