import React from 'react';
import CustomForm from './FormBook';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

const url = 'https://bcej.us13.list-manage.com/subscribe/post?u=1a05048b99e056a06bf750c3a&amp;id=5b22b251b0&amp;v_id=5092&amp;f_id=0063fae6f0';

const Demo = () => {
    return (
        <div>

            <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                    <CustomForm status={status} message={message} onValidated={subscribe} />
                )}
            />
        </div>
    );
};

export default Demo; 
