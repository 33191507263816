import React from "react";
import "./SobreAutor.css";

import gb from "../../assets/imgs/IMG_1817 (2).jpg";

const SobreAutor = () => {
    return (
        <div class="sobreautor-wrapper">
            <div className="container">
                <div className="textContainer">
                    <h2 className="heading">Gabriel Bulhões</h2>
                    <h3>Advogado Criminalista</h3>
                    
                    <ul>
                        <li>Advogado Criminalista</li>
                        <li>Head do Projeto plataforma.EthosBrasil.org</li>
                        <li>Doutorando em Ciências Criminais pela PUC/RS</li>
                        <li>Mestre em Ciências Criminais pela PUC/RS, sob orientação do Professor Nereu Giacomolli e co-orientação do Professor Jacinto Coutinho</li>
                        <li>Professor da Pós-graduação em Direito e Processo Penal da Universidade Potiguar (UnP) (Módulos: Recursos no Processo Penal e Crimes Tributários/Previdenciários)</li>
                        <li>Professor da Pós-graduação em Direito Criminal da Faculdade Católica de Mossoró/RN (Módulos: Recursos no Processo Penal e Criminologia)</li>
                        <li>Membro do Conselho Científico da Revista FIDES (Filosofia do Direito, Estado e Sociedade) da UFRN</li>
                        <li>Membro do Conselho Científico e Parecerecista da Revista InVerbis da UFRN</li>
                    </ul>
                </div>
                <div className="imageContainer">
                    <img
                        src={gb}
                        alt="Imagem do Profissional"
                        className="image"
                    />
                </div>
            </div>
        </div>
    );
};

export default SobreAutor;
