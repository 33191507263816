const type = 'Button';

const menuItems = [
    {
        label: 'Início',
        type: type,
        to: 'inicio',
    },
    {
        label: 'Escritório',
        type: type,
        to: 'escritorio',
    },
    {
        label: 'Newsletter',
        type: type,
        to: 'newsletter',
    }
];

export default menuItems;
